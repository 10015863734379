<template>
  <div class="preView flexMethods">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>试卷管理</el-breadcrumb-item>
        <el-breadcrumbItem :to="'/manage/paper/list/' + bankId">试卷列表</el-breadcrumbItem>
        <el-breadcrumb-item
          :to="{
            path: IsAuto ? `/manage/paper/auto?bankId=${bankId}` : '/manage/paper/editor?bankId=' + bankId,
            query: {
              IsEdit: $route.query.IsEdit,
              IsAuto: IsAuto || false,
              paperId: $route.query['paperId']
            }
          }"
          >{{ IsAuto ? '自动出题' : '手动出题' }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>试卷预览</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="display: flex;height: 100%;   padding: 10px;">
      <div class="pre-left flexMethods">
        <div class="Pre-title flexCenter">
          <div class="paperType">
            <p v-if="!IsupdateExamName">
              <Input
                v-model="paperTitle"
                style="width: 143px"
                :maxlength="20"
                show-word-limit
                placeholder="请输入试卷名称..."
                @on-focus="IsupdateExamName = true"
              />
            </p>
            <div style="width: 400px;position: relative" v-else>
              <Input
                style="width: 350px;"
                autofocus
                :value="paperTitle"
                :maxlength="20"
                @on-change="setNewPaperTitle"
                @keyup.enter.native="IsupdateExamName = false"
                @on-blur="IsupdateExamName = false"
              />
              <div class="flexCenter" style="position: absolute;right: 33px;top: 0;height: 100%">
                <svg aria-hidden="true" class="icon" style="margin: 0 15px;color: #5ABC50;" @click="IsupdateExamName = false">
                  <use xlink:href="#icon-gouxuan" />
                </svg>
                <svg aria-hidden="true" class="icon" @click="IsupdateExamName = false" style="color: #E66B42;font-size: 11px;margin-right: 5px;">
                  <use xlink:href="#icon-guanbi1" />
                </svg>
              </div>
            </div>
            <!--            <div class="buttonType flexCenter" style="">-->
            <!--              <div v-for="b in btnList" :key="b.id" @click="btnId = b.id">-->
            <!--                <Button :class="btnId === b.id ? 'select' : ''"-->
            <!--                  ><span>{{ b.name }}</span></Button-->
            <!--                >-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="Pre-body flexMethods">
          <p class="flexAliCenter" style="height: 66px;padding-left: 40px">
            试题总量<span class="countNumStyle" style="font-size: 18px">{{ getQuestionCount() }}</span>
          </p>
          <div style="max-height: 560px;height: calc(100% - 66px);overflow-y: auto;overscroll-behavior: contain;">
            <div
              class="section-item"
              style="display: flex;justify-content: flex-start;flex-direction: column"
              v-for="(section, index) in selectedQuestionList"
              :key="index"
            >
              <div v-if="section.questionVOS.length !== 0" class="header flexAliCenter" style="padding-left: 40px;background: #EAEDF1;height: 46px">
                <span
                  >{{ section.name }}&nbsp;<span class="countNumStyle" style="font-size: 16px">{{ section.questionVOS.length }}</span
                  >&nbsp;题</span
                >
                &nbsp;&nbsp;
                <div class="flexAliCenter">
                  <Input
                    class="inputStyle"
                    type="text"
                    @on-blur="setIntNumber($event, index)"
                    @keyup.enter.native="setIntNumber($event, index)"
                    autofocus
                    :value="gettotalP(section.questionVOS)"
                  />
                </div>
                <div class="flexCenter">
                  <span style="margin-right: 20px">&nbsp;分</span>
                  <div class="flexCenter" @mouseenter="Isdelete = true" @mouseleave="Isdelete = false">
                    <Tooltip class="flexAliCenter" content="删除" placement="bottom">
                      <JYImg v-if="!Isdelete" :Url="require('../../../../../assets/delete.png')" @click="handleRemoveSection(index)" />
                      <JYImg v-else :Url="require('../../../../../assets/deleteH.png')" @click="handleRemoveSection(index)" />
                    </Tooltip>
                  </div>
                  <div class="flexCenter" @mouseenter="IsAdd = true" @mouseleave="IsAdd = false">
                    <Tooltip content="新增" placement="bottom">
                      <JYImg v-if="!IsAdd" :Url="require('../../../../../assets/add.png')" @click="addSectionQ(index, section.name)" />
                      <JYImg v-else :Url="require('../../../../../assets/addH.png')" @click="addSectionQ(index, section.name)" />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div style="padding-right: 45px;max-width: 60%">
                <div
                  :id="'anchor-' + question.questionId"
                  class="question-item"
                  @mouseenter="current"
                  v-for="(question, qIndex) in section.questionVOS"
                  :key="question.questionId"
                >
                  <div>
                    <span>{{ qIndex + 1 }}、</span>
                    <span>[{{ ytConstant.questionType.getLabel(question.questionType) }}]</span>
                    <span style="margin: 0 10px;display: flex">
                      <InputNumber
                        @on-change="val => setTotalScore(val, index, qIndex)"
                        :value="question.points"
                        :precision="0"
                        style="width: 50px"
                        size="small"
                        :min="1"
                      />&nbsp;分
                    </span>
                    <span
                      class="flexAliCenter"
                      v-if="question.questionId === Number(currentId)"
                      @mouseenter="IsdeleByIndex = 'dele+' + qIndex"
                      @mouseleave="IsdeleByIndex = ''"
                    >
                      <Tooltip content="删除" placement="top">
                        <JYImg
                          v-if="IsdeleByIndex !== 'dele+' + qIndex"
                          :Url="require('../../../../../assets/delete.png')"
                          @click="delQuestion(index, qIndex)"
                        />
                        <JYImg v-else :Url="require('../../../../../assets/deleteH.png')" @click="delQuestion(index, qIndex)" />
                      </Tooltip>
                    </span>
                  </div>
                  <BaseQuestion :question="question" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <typeSidebar
        :isSubmiting="isSubmiting"
        :currentId="currentId"
        :count="countNume"
        @isAutoClassfi="Isautoclassfi"
        @anchorByVal="getAnchor"
        @created="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import vuescroll from 'vuescroll'
import typeSidebar from './typeSidebar'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BaseQuestion from '../../../../common/questions/BaseQuestion'
import PaperAPI from '@/api/paper'
import JYImg from '../../../../common/JYImg'
import JYIcon from '../../../../common/JYIcon'

export default {
  name: 'preViewExam',
  components: { vuescroll, typeSidebar, BaseQuestion, JYImg, JYIcon },
  data() {
    return {
      countNume: 0,
      bankId: 0,
      examName: this.$route.query.examName,
      IsupdateExamName: false,
      btnId: 1,
      btnList: [
        {
          id: 0,
          name: '简单'
        },
        {
          id: 1,
          name: '中等'
        },
        {
          id: 2,
          name: '困难'
        }
      ],
      isSubmiting: false,
      currentId: '',
      selectDifficulty: '',
      paper: null,
      Isdelete: false,
      IsAdd: false,
      IsdeleByIndex: '',
      IsAuto: false
    }
  },
  destroyed() {
    sessionStorage.removeItem('store')
  },
  mounted() {
    this.bankId = this.$route.query.bankId
    // 页面判定是初次加载||刷新
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
      sessionStorage.removeItem('store')
    } else {
      // if (this.selectedQuestionList.length === 0) {
      //   // 新建试卷 题目为空 返回出题页面
      //   this.$message.warning('请先选择题目...')
      //   return this.$router.push(`/manage/paper/editor?bankId=${this.bankId}`)
      // } else
      if (this.selectedQuestionList.length === 0 && this.$route.query.IsEdit) {
        // 修改试卷 试卷为空返回试卷列表
        this.$message.warning('试卷为空...')
        return this.$router.push(`/manage/paper/list/${this.bankId}`)
      }
    }
    // 防止页面刷新，存取vuex数据
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
    // 自动出卷
    this.IsAuto = this.$route.query.IsAuto || false
    this.IsAuto ? (this.btnId = this.$route.query.difficulty) : ''
  },
  computed: {
    ...mapState('question', ['paperTitle']),
    ...mapGetters('question', ['getEditorData']),
    canCancel: {
      get() {
        return this.getEditorData.isClassify
      },
      set(val) {
        this.setEditorData({
          isClassify: val
        })
      }
    },
    selectedQuestionList: {
      get() {
        return this.$store.state.question.selectedQuestionList
      },
      set(newVal) {
        this.$store.commit('question/setSelectedQuestionList', newVal)
      }
    }
  },
  methods: {
    ...mapMutations('question', ['deleteSection', 'setPaperTitle', 'setSectionName', 'setEditorData']),
    ...mapActions('question', ['loadEditPaperDetail']),
    topronlemByBankId() {
      return {
        path: '/exam/paper/editor?bankId=' + this.bankId,
        query: {
          IsEdit: this.$route.query.IsEdit || false
        }
      }
    },
    async handleSubmit() {
      // 如果是修改试卷，会带上试卷id
      let paperId = 0
      if (this.paper) {
        paperId = this.paper.paperId
      } else {
        paperId = this.$route.query['paperId']
      }
      const bankId = this.$route.query['bankId']
      if (!bankId) {
        this.$message.error('没有指定试卷库')
        return
      } else if (!this.paperTitle) {
        this.$message.error('试卷标题不能为空')
        return
      }
      this.isSubmiting = true
      const formData = {
        name: this.paperTitle,
        paperBankId: bankId,
        paperId,
        level: this.btnId,
        status: 1,
        sectionForms: this.getCreateFormData()
      }
      try {
        let res = await PaperAPI.createPaper(formData)
        if (res.code === 0) {
          this.$message.success(paperId ? '修改试卷成功' : '新建试卷成功')
          this.$router.push(`/manage/paper/list/${bankId}`)
          sessionStorage.removeItem('store')
        }
      } catch (e) {
      } finally {
        this.isSubmiting = false
      }
    },
    setNewPaperTitle(e) {
      let newTitle = e.srcElement.value
      this.setPaperTitle(newTitle)
    },
    getCreateFormData() {
      return this.selectedQuestionList.map(section => ({
        name: section.name,
        questionForms: section.questionVOS.map(question => ({
          questionId: question.questionId || question.id,
          points: Number.parseInt(question.points) || 0
        }))
      }))
    },
    setScore(val, index) {
      let a = JSON.parse(JSON.stringify(this.selectedQuestionList))
      let p = Math.ceil(val / a[index].questionVOS.length)
      a[index].questionVOS.map(item => {
        item.points = p
      })
      a.map(item => {
        let s = 0
        item.questionVOS.map(q => {
          s += q.points
        })
        item.totalPoints = s
      })
      this.selectedQuestionList = a
    },
    Isautoclassfi(data) {
      if (data) {
        this.classify()
      } else {
        this.cancelClassify()
      }
    },
    getAnchor(val) {
      this.currentId = val.substr(8)
      this.$el.querySelector(val).scrollIntoView({
        behavior: 'smooth' // 平滑过渡
        // block: 'start' // 上边框与视窗顶部平齐
      })
    },
    addSectionQ(index, name) {
      let type = ''
      switch (name) {
        case '判断题':
          type = 0
          break
        case '单选题':
          type = 1
          break
        case '多选题':
          type = 2
          break
        case '填空题':
          type = 3
          break
        case '简答题':
          type = 4
          break
        case '代码题':
          type = 5
          break
        default:
          type = 6
          break
      }
      const paperbankid = this.$route.query['bankId']
      this.setEditorData({
        isClassify: this.canCancel,
        currentIndex: index,
        type: type
      })
      const paperId = this.$route.query['paperId']
      if (paperId) {
        this.$router.push(`/manage/paper/editor?bankId=${paperbankid}&paperId=${paperId}&diff=${this.btnId}`)
      } else {
        this.$router.push(`/manage/paper/editor?bankId=${paperbankid}&diff=${this.btnId}`)
      }
    },
    current(val) {
      this.currentId = val.target.id.substring(7)
    },
    gettotalP(data) {
      let total = 0
      data.forEach(d => {
        total += d.points
      })
      return total
    },
    setIntNumber(e, index) {
      let flag = new RegExp('^[1-9]([0-9])*$').test(e.target.value)
      if (!flag) {
        this.$message.warning('请输入整数')
        return (e.target.value = 0)
      }
      this.setScore(e.target.value, index)
    },
    handleRemoveSection(index) {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确认删除该大题和该大题下的所有题目吗？</p>',
        onOk: () => {
          this.deleteSection(index)
          let questionLength = 0
          this.selectedQuestionList.forEach(v => {
            questionLength += v.questionVOS.length
          })
          if (questionLength === 0) {
            this.canCancel = false
            // this.$store.commit('question/setUpdateByQuestion', false)
            if (this.$route.query.IsAuto) {
              this.$message.warning('请先配置题目')
              return this.$router.push(`/manage/paper/auto?bankId=${this.bankId}`)
            } else {
              this.$message.warning('请先选择题目...')
              return this.$router.push(`/manage/paper/editor?bankId=${this.bankId}`)
            }
          }
        }
      })
    },
    cancelClassify() {
      let a = []
      this.selectedQuestionList.map(item => {
        item.questionVOS.map(q => {
          a.push(q)
        })
      })
      let obj = {
        name: '默认大题',
        questionVOS: a
      }
      let arr = [obj]
      this.selectedQuestionList = arr
      this.resetTotalScore()
      this.canCancel = false
    },
    classify() {
      let map = {}
      let dest = []
      for (let i = 0; i < this.selectedQuestionList[0].questionVOS.length; i++) {
        let ai = this.selectedQuestionList[0].questionVOS[i]
        if (!map[ai.questionType]) {
          dest.push({
            name: this.ytConstant.questionType.getLabel(ai.questionType),
            questionVOS: [ai]
          })
          map[ai.questionType] = ai
        } else {
          for (let j = 0; j < dest.length; j++) {
            let dj = dest[j]
            if (dj.name === this.ytConstant.questionType.getLabel(ai.questionType)) {
              dj.questionVOS.push(ai)
              break
            }
          }
        }
      }
      let compare = (x, y) => {
        let val1 = x.questionVOS[0].questionType
        let val2 = y.questionVOS[0].questionType
        if (val1 < val2) {
          return -1
        } else if (val1 > val2) {
          return 1
        } else {
          return 0
        }
      }
      let all = dest.sort(compare)
      all.map(item => {
        this.addClassification(item.name, item.questionVOS)
      })
      let arr = this.selectedQuestionList.slice()
      arr.splice(0, 1)
      this.selectedQuestionList = arr
    },
    addClassification(name, questionVOS) {
      const newSectionList = this.selectedQuestionList.concat({
        name,
        questionVOS
      })
      this.selectedQuestionList = newSectionList
      this.resetTotalScore()
      this.canCancel = true
    },
    resetTotalScore() {
      this.selectedQuestionList.map(item => {
        let s = 0
        item.questionVOS.map(q => {
          s += q.points
        })
        item.totalPoints = s
      })
    },
    setTotalScore(val, index, qindex) {
      let a = JSON.parse(JSON.stringify(this.selectedQuestionList))
      a[index].questionVOS[qindex].points = val
      a.map(item => {
        let s = 0
        item.questionVOS.map(q => {
          s += q.points
        })
        item.totalPoints = s
      })
      this.selectedQuestionList = a
    },
    delQuestion(index, qIndex) {
      let a = JSON.parse(JSON.stringify(this.selectedQuestionList))
      a[index].questionVOS.splice(qIndex, 1)
      a.map(item => {
        let s = 0
        item.questionVOS.map(q => {
          s += q.points
        })
        item.totalPoints = s
      })
      this.selectedQuestionList = a
    },
    getQuestionCount() {
      let count = 0
      let data = this.selectedQuestionList
      data.map(d => {
        count += d.questionVOS.length
      })
      this.countNume = count
      return count
    }
  }
}
</script>

<style lang="less" scoped>
.inputStyle {
  ::v-deep .ivu-input {
    width: 50px;
    height: 25px;
  }
}
.flexAliCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexMethods {
  display: flex;
  flex-direction: column;
}
.preView {
  font-size: 14px;
  color: #3a4e64;
  width: 100%;
  height: 100%;

  .countNumStyle {
    color: #568feb;
  }
  .hrStyle {
    height: 1px;
    border: 0;
    background: #dedede;
    margin: 5px 0 20px 0;
  }
  .iconStyle {
    cursor: pointer;
    font-size: 17px;
    margin-right: 16px;
  }
  .pre-left {
    width: 73%;
    /*min-width: 970px;*/
    /*overflow: hidden;*/
    height: 100%;
    //margin-top: 15px;
    .Pre-title {
      height: 134px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.16);
      margin-bottom: 10px;
      .buttonType {
        margin-top: 20px;
        button {
          color: #568feb;
          margin-right: 20px;
          font-size: 14px;
          width: 68px;
          height: 28px;
          border-color: #dedede;
          box-shadow: none;
        }
        .select {
          color: #fff;
          background: #568feb;
        }
      }
    }
    .Pre-body {
      background: #fff;
      flex: auto;
      height: calc(100% - 144px);
      box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.16);
      .question-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 40px;
        margin-bottom: 43px;
        &:first-child {
          margin-top: 23px;
        }
        div {
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }
}
::v-deep .options-container {
  text-align: left;
  .question-image-size-restrict {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}

::v-deep .ivu-tooltip-rel {
  display: flex;
}
</style>
