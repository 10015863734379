<template>
  <div class="Pre-right">
    <div class="title flexAliCenter">
      <div class="flexAliCenter">
        <span
          >试题总量&nbsp;<span class="countNumStyle" style="font-size: 18px">{{ count }}</span
          >&nbsp;题</span
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="flexAliCenter" @mouseenter="Isdelete = true" @mouseleave="Isdelete = false">
          <Tooltip content="删除" placement="bottom">
            <JYImg v-if="!Isdelete" :Url="require('../../../../../assets/delete.png')" @click="deleAll" />
            <JYImg v-else :Url="require('../../../../../assets/deleteH.png')" @click="deleAll" />
          </Tooltip>
        </div>
        <div class="flexAliCenter" @mouseenter="IsAdd = true" @mouseleave="IsAdd = false">
          <Tooltip content="新增" placement="bottom">
            <JYImg v-if="!IsAdd" :Url="require('../../../../../assets/add.png')" @click="toSelectType" />
            <JYImg v-else :Url="require('../../../../../assets/addH.png')" @click="toSelectType" />
          </Tooltip>
        </div>
      </div>
      <Button @click="IsautoClassfi" style="width: 78px;height: 28px;font-size: 12px" type="primary">
        {{ canCancel ? '取消分类' : '自动分类' }}
      </Button>
    </div>
    <hr class="hrStyle" />
    <div style="height: calc(100% - 156px);max-height: calc(100% - 156px);overflow-y: auto;overscroll-behavior: contain;">
      <draggable v-model="selectedQuestionList" group="sectionList">
        <div
          class="flexAliCenter"
          v-for="(section, sIndex) in selectedQuestionList"
          :key="sIndex"
          style="display: flex;flex-direction: column;margin-bottom: 20px;cursor: move"
        >
          <div class="flexCenter" style="width: 100%;display: flex;padding: 0 20px;justify-content: space-between;">
            <div class="flexAliCenter" style="width: 50%">
              <div v-if="IsupdateQuestionName !== 'update+' + sIndex" style="white-space: nowrap;">
                <span style="cursor: pointer" @click="IsupdateQuestionName = 'update+' + sIndex">{{ section.name }}&nbsp;</span>
                <Input class="inputStyle1" type="text" @on-change.stop="setIntNumber($event, sIndex)" :value="gettotalP(section.questionVOS)" />
                <span>&nbsp;分</span>
              </div>
              <Input
                :maxlength="7"
                show-word-limit
                :value="section.name"
                v-else
                @keyup.enter.native="handleSectionNameChange($event, sIndex)"
                @on-blur="handleSectionNameChange($event, sIndex)"
              />
            </div>
            <div class="flexAliCenter">
              <div class="flexAliCenter" @mouseenter="IsDeleteByIndex = 'dele+' + sIndex" @mouseleave="IsDeleteByIndex = ''">
                <Tooltip content="删除" placement="bottom">
                  <JYImg
                    v-if="IsDeleteByIndex !== 'dele+' + sIndex"
                    :Url="require('../../../../../assets/delete.png')"
                    @click="handleRemoveSection(sIndex)"
                  />
                  <JYImg v-else :Url="require('../../../../../assets/deleteH.png')" @click="handleRemoveSection(sIndex)" />
                </Tooltip>
              </div>
              <div class="flexAliCenter" @mouseenter="IsAddByIndex = 'add+' + sIndex" @mouseleave="IsAddByIndex = ''">
                <Tooltip content="增加" placement="bottom">
                  <JYImg
                    v-if="IsAddByIndex !== 'add+' + sIndex"
                    :Url="require('../../../../../assets/add.png')"
                    @click="addSectionQ(sIndex, section.name)"
                  />
                  <JYImg v-else :Url="require('../../../../../assets/addH.png')" @click="addSectionQ(sIndex, section.name)" />
                </Tooltip>
              </div>
            </div>
          </div>
          <div class="body flexAliCenter" style="width: 100%;">
            <draggable
              group="questionList"
              @input="val => changeOrder(val, sIndex)"
              :value="section.questionVOS"
              style="min-height: 50px; width: 100%"
              class="grid-container"
            >
              <div
                style="width: 35px;height: 35px;margin-right: 10px;border-radius: 3px"
                @click="anchor('#anchor-' + question.questionId)"
                v-for="(question, index) in section.questionVOS"
                :key="index"
                :class="{
                  'preview-question-item': true,
                  current: question.questionId === Number(currentId)
                }"
              >
                {{ index + 1 }}
              </div>
            </draggable>
          </div>
        </div>
      </draggable>
      <div class="flexAliCenter paLeft add-section" style="margin: 10px 0">
        <div
          class="flexCenter"
          v-if="!IsaddQuestionType"
          @click="addQuestionType()"
          @mouseenter="IsAddQuestionName = true"
          @mouseleave="IsAddQuestionName = false"
        >
          <Tooltip content="新增" placement="bottom">
            <JYImg v-if="!IsAddQuestionName" :Url="require('../../../../../assets/addQuestionName.png')" @click="addQuestionType()" />
            <JYImg v-else :Url="require('../../../../../assets/addH.png')" @click="addQuestionType()" />
          </Tooltip>
          <Input class="inputStyle" v-model="newSectionName" />
        </div>
        <div v-else style="position:relative;cursor:pointer;">
          <Input
            v-model="newSectionName"
            autofocus
            placeholder="请输入大题名字"
            :maxlength="7"
            show-word-limit
            @keyup.enter.native="handleAddSection"
          />
          <div class="flexCenter" style="position: absolute;right: 10px;top: 0;height: 100%">
            <!--              <svg aria-hidden="true" class="icon" style="margin: 0 15px;color: #5ABC50" @click="handleAddSection">-->
            <!--                <use xlink:href="#icon-gouxuan"/>-->
            <!--              </svg>-->
            <svg aria-hidden="true" class="icon" @click="IsaddQuestionType = false" style="font-size: 11px;color: #E66B42;margin-right: 25px">
              <use xlink:href="#icon-guanbi1" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="footer flexCenter">
      <Button type="primary" @click="createdExam" :loading="value">生成试卷</Button>
      <Button type="primary" @click="topronlemByBankId">取消</Button>
    </div>
    <div v-if="openTypeModal">
      <Modal class="typeModal" width="484" footer-hide :mask-closable="false" v-model="openTypeModal" @on-cancel="openTypeModal = false">
        <div class="flexCenter title">选择大题</div>
        <p class="flexCenter descTitle">选择需要添加的题目的大题</p>
        <div class="selectType">
          <RadioGroup class="radioGroupClass" v-model="selectedQuestion">
            <Radio v-for="(section, sIndex) in selectedQuestionList" :key="sIndex" :label="sIndex + section.name">{{ section.name }}</Radio>
          </RadioGroup>
        </div>
        <p v-if="!isAddTypeByModal" class="addNew flexAliCenter" style="display: flex" @click="isAddTypeByModal = true">
          <JYIcon :href="'#icon-xinzeng'" @click="isAddTypeByModal = true" />
          <span style="margin-left: 6px">{{ newSectionName }}</span>
        </p>
        <div class="addNew" style="position: relative" v-else>
          <Input style="width: 350px;" v-model="newSectionName" autofocus placeholder="请输入大题名字" @keyup.enter.native="handleAddSection" />
          <div style="position: absolute;right: 45px;top: 5px">
            <svg aria-hidden="true" class="icon" style="margin: 0 10px;color: #5ABC50" @click="handleAddSection">
              <use xlink:href="#icon-gouxuan" />
            </svg>
            <svg aria-hidden="true" class="icon closeIcon" @click="isAddTypeByModal = false" style="color: #E66B42;font-size: 11px">
              <use xlink:href="#icon-guanbi1" />
            </svg>
          </div>
        </div>
        <div class="footer flexCenter">
          <Button size="large" style="margin-right: 20px" @click="openTypeModal = false">取消</Button>
          <Button type="primary" size="large" @click="toSelectQuestion">继续选题</Button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import vuescroll from 'vuescroll'
import draggable from 'vuedraggable'
import JYIcon from '@components/common/JYIcon'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: 'typeSidebar',
  components: { vuescroll, draggable, JYIcon },
  props: {
    count: {
      type: Number,
      default: 0
    },
    isSubmiting: {
      type: Boolean,
      default: false
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      Isclassifiction: false,
      IsaddQuestionType: false,
      newSectionName: '默认大题',
      value: this.isSubmiting,
      openTypeModal: false,
      selectedQuestion: '',
      newAddTypeName: '默认大题',
      isAddTypeByModal: false,
      IsAdd: false,
      Isdelete: false,
      IsDeleteByIndex: '',
      IsAddByIndex: '',
      IsAddQuestionName: false,
      IsupdateQuestionName: '',
      bankId: this.$route.query.bankId,
      timer: null
    }
  },
  mounted() {
    // if (this.canCancel) {
    //   this.$store.commit('question/setUpdateByQuestion', true)
    // } else {
    //   this.$store.commit('question/setUpdateByQuestion', false)
    // }
  },
  computed: {
    ...mapState('question', ['paperTitle']),
    ...mapGetters('question', ['getEditorData', 'getUpdateByQuestion']),
    IscancelClassify: {
      // 是否使用自动分类
      get() {
        return this.getUpdateByQuestion
      }
    },
    canCancel: {
      get() {
        return this.getEditorData.isClassify
      },
      set(val) {
        this.setEditorData({
          isClassify: val
        })
      }
    },
    selectedQuestionList: {
      get() {
        return this.$store.state.question.selectedQuestionList
      },
      set(newVal) {
        this.$store.commit('question/setSelectedQuestionList', newVal)
      }
    },
    submitable() {
      return this.paperTitle !== '' && this.selectedQuestionList.length > 0
    },
    computeTotalScore() {
      let total = 0
      this.selectedQuestionList.forEach(section => {
        total += section.totalPoints
      })
      return total
    },
    paperEditorStyle() {
      return this.selectedQuestionList.length === 0 ? 'height: 100%' : ''
    }
  },
  methods: {
    ...mapMutations('question', ['deleteSection', 'setPaperTitle', 'setSectionName', 'setEditorData']),
    ...mapActions('question', ['loadEditPaperDetail']),
    handleSectionNameChange(e, sIndex) {
      this.IsupdateQuestionName = ''
      const newVal = e.srcElement.value
      this.setSectionName({ sIndex, name: newVal })
      this.$store.commit('question/setUpdateByQuestion', true)
      this.canCancel = true
    },
    handleAddSection() {
      if (this.newSectionName.trim() === '') {
        this.$message.warning('大题名字不能为空')
        return
      }
      const newSectionList = this.selectedQuestionList.concat({
        name: this.newSectionName,
        questionVOS: [],
        totalPoints: 0
      })
      this.selectedQuestionList = newSectionList
      this.newSectionName = '默认大题'
      this.IsaddQuestionType = false
      this.isAddTypeByModal = false
      this.$store.commit('question/setUpdateByQuestion', true)
      this.canCancel = true
    },
    toSelectType() {
      this.openTypeModal = true
    },
    deleAll() {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确认删除所有题目吗？</p>',
        onOk: () => {
          this.selectedQuestionList = []
          let questionLength = 0
          this.selectedQuestionList.forEach(v => {
            questionLength += v.questionVOS.length
          })
          if (questionLength === 0) {
            this.canCancel = false
            // this.$store.commit('question/setUpdateByQuestion', false)
            if (this.$route.query.IsAuto) {
              this.$message.warning('请先配置题目')
              return this.$router.push(`/manage/paper/auto?bankId=${this.bankId}`)
            } else {
              this.$message.warning('请先选择题目...')
              return this.$router.push(`/manage/paper/editor?bankId=${this.bankId}`)
            }
          }
        }
      })
    },
    createdExam() {
      if (this.count === 0 || this.paperTitle.trim() === '') {
        this.value = false
        return this.$message.warning('试卷题目或名称不能为空!')
      }
      this.value = true
      this.timer = setTimeout(() => {
        this.value = false
      }, 3000)
      this.$emit('created')
    },
    topronlemByBankId() {
      let path = ''
      this.$route.query.IsAuto
        ? (path = `/manage/paper/auto?bankId=${this.$route.query.bankId}`)
        : (path = `/manage/paper/editor?bankId=${this.$route.query.bankId}`)
      this.setBackRouter(path)
    },
    setBackRouter(path) {
      this.$router.push({
        path: path,
        query: {
          IsEdit: this.$route.query.IsEdit || false,
          paperId: this.$route.query.paperId,
          IsAuto: true
        }
      })
    },
    toSelectQuestion() {
      if (this.selectedQuestion.trim() === '') {
        return this.$message.warning('请选择大题...')
      }
      let index = Number(this.selectedQuestion.substr(0, 1))
      let name = Number(this.selectedQuestion.substr(1))
      this.addSectionQ(index, name)
    },
    addSectionQ(index, name) {
      let type = ''
      switch (name) {
        case '判断题':
          type = 0
          break
        case '单选题':
          type = 1
          break
        case '多选题':
          type = 2
          break
        case '填空题':
          type = 3
          break
        case '简答题':
          type = 4
          break
        case '代码题':
          type = 5
          break
        default:
          type = 6
          break
      }
      const paperbankid = this.$route.query['bankId']
      this.setEditorData({
        cancelClassify: this.IscancelClassify,
        isClassify: this.canCancel,
        currentIndex: index,
        type: type
      })
      const paperId = this.$route.query['paperId']
      if (paperId) {
        this.$router.push(`/manage/paper/editor?bankId=${paperbankid}&paperId=${paperId}&diff=${this.selectDifficulty}`)
      } else {
        this.$router.push(`/manage/paper/editor?bankId=${paperbankid}&diff=${this.selectDifficulty}`)
      }
    },
    gettotalP(data) {
      let total = 0
      data.forEach(d => {
        total += d.points
      })
      return total
    },
    setIntNumber(e, index) {
      let flag = new RegExp('^[1-9]([0-9])*$').test(e.target.value)
      if (!flag) {
        this.$message.warning('请输入整数')
        return (e.target.value = 0)
      }
      this.setScore(e.target.value, index)
    },
    setScore(val, index) {
      let a = JSON.parse(JSON.stringify(this.selectedQuestionList))
      let p = Math.ceil(val / a[index].questionVOS.length)
      a[index].questionVOS.map(item => {
        item.points = p
      })
      a.map(item => {
        let s = 0
        item.questionVOS.map(q => {
          s += q.points
        })
        item.totalPoints = s
      })
      this.selectedQuestionList = a
    },
    handleRemoveSection(index) {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确认删除该大题和该大题下的所有题目吗？</p>',
        onOk: () => {
          this.deleteSection(index)
          let questionLength = 0
          this.selectedQuestionList.forEach(v => {
            questionLength += v.questionVOS.length
          })
          if (questionLength === 0) {
            this.canCancel = false
            // this.$store.commit('question/setUpdateByQuestion', false)
            if (this.$route.query.IsAuto) {
              this.$message.warning('请先配置题目')
              return this.$router.push(`/manage/paper/auto?bankId=${this.bankId}`)
            } else {
              this.$message.warning('请先选择题目...')
              return this.$router.push(`/manage/paper/editor?bankId=${this.bankId}`)
            }
          }
        }
      })
    },
    changeOrder(val, i) {
      let a = JSON.parse(JSON.stringify(this.selectedQuestionList))
      a[i].questionVOS = val
      a.map(item => {
        let s = 0
        item.questionVOS.map(q => {
          s += q.points
        })
        item.totalPoints = s
      })
      this.selectedQuestionList = a
    },
    anchor(val) {
      this.currentId = val.substr(8)
      this.$emit('anchorByVal', val)
    },
    IsautoClassfi() {
      this.canCancel = !this.canCancel
      // 选中自动分类 就不可以查看全部
      this.$store.commit('question/setUpdateByQuestion', false)
      this.$emit('isAutoClassfi', this.canCancel)
    },
    addQuestionType() {
      this.IsaddQuestionType = true
    }
  },
  destroyed() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="less" scoped>
.inputStyle {
  ::v-deep .ivu-input {
    outline: none;
    border: none;
    box-shadow: none;
  }
}
.inputStyle1 {
  width: 50px;
  ::v-deep .ivu-input {
    width: 50px;
    height: 25px;
  }
}
.paLeft {
  padding-left: 20px;
}
.flexAliCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.countNumStyle {
  color: #568feb;
}
.hrStyle {
  height: 1px;
  border: 0;
  background: #dedede;
  margin: 5px 0 20px 0;
}
.iconStyle {
  cursor: pointer;
  font-size: 17px;
  margin-right: 20px;
}
.Pre-right {
  height: 100%;
  max-height: 100%;
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.16);
  font-size: 14px;
  color: #3a4e64;
  background: #fff;
  max-width: 300px;
  margin-left: 10px;
  flex: auto;
  .title {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    height: 56px;
  }
  .grid-container {
    display: flex;
    flex-wrap: wrap;
    /*display: grid;*/
    /*grid-template-columns: repeat(7, 1fr);*/
    /*grid-row-gap: 5px;*/
    border-bottom: solid 1px #e3e3e3;
    padding: 10px 20px;
    .preview-question-item {
      width: 32px;
      height: 32px;
      border: solid 1px #e3e3e3;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      cursor: move;
      &.current {
        color: #fff;
        background: #568feb;
        border: solid 2px #568feb;
      }
    }
  }
  .footer {
    height: 55px;
    button {
      width: 130px;
      height: 32px;
      border-radius: 3px;
      &:first-child {
        margin-right: 17px;
      }
    }
  }
}
.add-section {
  .ivu-input-with-suffix {
    padding-right: 50px;
  }
  .ivu-input-suffix {
    width: 45px;
  }
}
.typeModal {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #3a4e64;
  border-radius: 4px;
  .title {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 500;
    line-height: 42px;
  }
  .descTitle {
    font-size: 12px;
    color: #5e6470;
    font-weight: 300;
    margin-bottom: 30px;
  }
  .selectType {
    .radioGroupClass {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 5px;
    }
    ::v-deep .ivu-radio-wrapper {
      font-size: 16px;
      /*margin-right: 40px;*/
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .addNew {
    cursor: pointer;
    font-size: 16px;
    /*padding-left: 15px;*/
    margin-top: 22px;
    color: #6a8abe;
    input {
      font-size: 16px;
    }
  }
  .footer {
    margin: 37px 0 30px 0;
    display: flex;
    button {
      width: 90px;
      height: 36px;
      border-radius: 4px;
      outline: none;
      box-shadow: none;
      &:first-child {
        border: 1px solid #d2d2d2;
        color: #5e6470;
      }
      &:last-child {
        background: #6a8abe;
        box-shadow: none;
        border: none;
      }
    }
  }
}
@media screen and (max-width: 1441px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 5px;
  }
}
</style>
